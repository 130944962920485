export const jobTypeOption = [{
    label: "Full Time",
    value: "FullTime"
}, {
    label: "Part Time",
    value: "PartTime"
}];

export const genderOption = [{
    label: "Male",
    value: "Male"
}, {
    label: "Female",
    value: "Female"
}, {
    label: "Both",
    value: "Both"
}];

export const workingFromOption = [{
    label: "Home",
    value: "Home"
}, {
    label: "Office",
    value: "Office"
}, {
    label: "Hybrid",
    value: "Hybrid"
}];

export const workingShiftOption = [{
    label: "Day",
    value: "Day"
}, {
    label: "Night",
    value: "Night"
}, {
    label: "Mixed",
    value: "Mixed"
}];

export const languageOptions = [{
    label: "English",
    value: "English"
}, {
    label: "Hindi",
    value: "Hindi"
}];

export const incentivePeriodOption = [{
    label: "One Time",
    value: "oneTime"
}, {
    label: "Monthly",
    value: "monthly"
}];

export const Duration = [{
    label: "15 Min",
    value: "15 min"
}, {
    label: "30 Min",
    value: "30 min"
}, {
    label: "45 Min",
    value: "45 min"
}, {
    label: "60 Min",
    value: "60 min"
}];

export const SalaryExp = [{
    label: "4000",
    value: "4000"
}, {
    label: "6000",
    value: "6000"
}, {
    label: "8000",
    value: "8000"
}, {
    label: "10,000",
    value: "10,000"
}];

export const Bouns = [{
    label: "One Time",
    value: "one time"
}, {
    label: "Mothly",
    value: "mothly"
},];

export const Role = [{
    label: "web designer",
    value: "UI/UX Designer"
}, {
    label: "web designer",
    value: "web designer"
},];
export const language = [{
    label: "English",
    value: "English"
}, {
    label: "Hindi",
    value: "Hindi"
},];


export const requirStaff = [{
    label: "English",
    value: "English"
}, {
    label: "Hindi",
    value: "Hindi"
},];

export const jobnoticPeriod = [{
    label: "One Month",
    value: "15 Days"
}, {
    label: "One Month",
    value: "15 Days"
}];

export const jobOfferDate = [{
    label: "30 July 2022",
    value: "30 July 2022"
}, {
    label: "25 July 2022",
    value: "25 July 2022"
}];

export const JobOfferTime = [{
    label: "09:00 AM",
    value: "09:00 AM"
}, {
    label: "10:00 AM",
    value: "10:00 AM"
}];

export const JobOfferstaffNecessity = [{
    label: "Immediate (Within 7 days)",
    value: "Immediate (Within 7 days)"
}, {
    label: "Within 2 weeks (15 days)",
    value: "Within 2 weeks (15 days)"
}, {
    label: "Within 4 weeks (1 month)",
    value: "Within 4 weeks (1 month)"
}, {
    label: "Within 2 months",
    value: "Within 2 months"
}, {
    label: "Within 3 months",
    value: "Within 3 months"
}, {
    label: "More than 3 months",
    value: "More than 3 months"
}];

export const JobOfferOneTimeBonus = [{
    label: "Monthly",
    value: "Monthly"
}, {
    label: "Yearly",
    value: "Yearly"
}, {
    label: "One Time",
    value: "One Time"
}];

export const representativeRole = [{
    label: "HR/Recruiter",
    value: "HR/Recruiter"
}, {
    label: "CEO/Founder",
    value: "CEO/Founder"
}, {
    label: "Business Owner",
    value: "Business Owner"
}, {
    label: "CTO/IT",
    value: "CTO/IT"
}, {
    label: "CMO/Marketing",
    value: "CMO/Marketing"
}, {
    label: "CFO/Finance",
    value: "CFO/Finance"
}, {
    label: "COO/Operations",
    value: "COO/Operations"
}, {
    label: "Other",
    value: "Other"
}];

export const companySize = [{
    label: "1-10 Employees",
    value: "1-10 Employees"
}, {
    label: "10-20 Employees",
    value: "10-20 Employees"
}, {
    label: "20-50 Employees",
    value: "20-50 Employees"
}, {
    label: "50-100 Employees",
    value: "50-100 Employees"
}, {
    label: "100-500 Employees",
    value: "100-500 Employees"
}, {
    label: "500 and Up",
    value: "500 and Up"
}];

export const timezoneOptions = [{
    label: "Asia/Kolkata (UTC +5:30)",
    value: "Asia/Kolkata"
}, {
    label: "Asia/Jerusalem (UTC +3:00)",
    value: "Asia/Jerusalem"
}, {
    label: "Europe/GMT (UTC +0:00)",
    value: "Europe/GMT"
}]

export const currencyOptions = [{
    label: "USD",
    value: "USD"
}, {
    label: "EUR",
    value: "EUR"
}, {
    label: "CRC",
    value: "CRC"
}, {
    label: "GBP",
    value: "GBP"
}, {
    label: "ILS",
    value: "ILS"
}, {
    label: "INR",
    value: "INR"
}, {
    label: "JPY",
    value: "JPY"
}, {
    label: "KRW",
    value: "KRW"
}, {
    label: "NGN",
    value: "NGN"
}, {
    label: "PHP",
    value: "PHP"
}, {
    label: "PLN",
    value: "PLN"
}, {
    label: "PYG",
    value: "PYG"
}, {
    label: "THB",
    value: "THB"
}, {
    label: "UAH",
    value: "UAH"
}, {
    label: "VND",
    value: "VND"
}];



export const challengeTypeOption = [{
    label: "IQ Tests",
    value: "IQ Tests"
}, {
    label: "Mathematical Skills",
    value: "Mathematical Skills"
}, {
    label: "Visual Pursuit",
    value: "Visual Pursuit"
}, {
    label: "Motorical Skills",
    value: "Motorical Skills"
}, {
    label: "English Skills",
    value: "English Skills"
}, {
    label: "Consistency Score",
    value: "Consistency Score"
}, {
    label: "General Awareness",
    value: "General Awareness"
}, {
    label: "Tech Abilities",
    value: "Tech Abilities"
}, {
    label: "Emotional Questions",
    value: "Emotional Questions"
},  {
    label: "Logical Reasoning",
    value: "Logical Reasoning"
}, {
    label: "Riding Ability Test",
    value: "Riding Ability Test"
}, {
    label: "Digital Markting",
    value: "Digital Markting"
}, {
    label: "Cook/Chef",
    value: "Cook/Chef"
}];



export const userTargetOption = [{
    label: "All",
    value: "All"
}, {
    label: "Candidate",
    value: "Candidate"
}, {
    label: "Employer",
    value: "Employer"
}];

export const questionTypeOption = [{
    label: "Text",
    value: "Text"
}, {
    label: "Image",
    value: "Image"
}, {
    label: "Video",
    value: "Video"
}, {
    label: "Audio",
    value: "Audio"
}];

export const userLevelOption = [{
    label: "Easy",
    value: "Easy"
}, {
    label: "Medium",
    value: "Medium"
}, {
    label: "Hard",
    value: "Hard"
}];

export const questionTimeOption = [{
    label: "30 Sec.",
    value: "30"
}, {
    label: "60 Sec.",
    value: "60"
}, {
    label: "90 Sec.",
    value: "90"
}, {
    label: "120 Sec.",
    value: "120"
}, {
    label: "150 Sec.",
    value: "150"
},
{
    label: "180 Sec.",
    value: "180"
},
{
    label: "210 Sec.",
    value: "210"
},
{
    label: "240 Sec.",
    value: "240"
},
{
    label: "270 Sec.",
    value: "270"
},
{
    label: "300 Sec.",
    value: "300"
},
{
    label: "330 Sec.",
    value: "330"
},
{
    label: "360 Sec.",
    value: "360"
},
{
    label: "390 Sec.",
    value: "390"
},
{
    label: "420 Sec.",
    value: "420"
},
{
    label: "450 Sec.",
    value: "450"
},
{
    label: "480 Sec.",
    value: "480"
},
{
    label: "510 Sec.",
    value: "510"
},
{
    label: "540 Sec.",
    value: "540"
},
{
    label: "570 Sec.",
    value: "570"
},
{
    label: "600 Sec.",
    value: "600"
}

];


export const answerTypeOption = [{
    label: "Image",
    value: "Image"
}, {
    label: "Text",
    value: "Text"
}];

export const answerCountOption = [{
    label: "2",
    value: "2"
}, {
    label: "3",
    value: "3"
}, {
    label: "4",
    value: "4"
}, {
    label: "5",
    value: "5"
}, {
    label: "6",
    value: "6"
}, {
    label: "7",
    value: "7"
}, {
    label: "8",
    value: "8"
}];


export const correctAnswerOption = [{
    label: "1",
    value: "1"
}, {
    label: "2",
    value: "2"
}, {
    label: "3",
    value: "3"
}, {
    label: "4",
    value: "4"
}, {
    label: "5",
    value: "5"
}, {
    label: "6",
    value: "6"
}, {
    label: "7",
    value: "7"
}, {
    label: "8",
    value: "8"
}]; 