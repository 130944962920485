import React, { useState, useEffect, useRef } from "react";
import styles from '../Challenges&Scores/ChallengesScores.module.scss';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import {
    companySize,
    representativeRole,
    challengeTypeOption,
    userTargetOption,
    questionTypeOption,
    userLevelOption,
    questionTimeOption,
    answerTypeOption,
    answerCountOption,
    correctAnswerOption
} from '../../common/DropdownOptions';
import { Dropdown } from "../../components/common/Dropdown/Dropdown";
import InputField from "../../components/common/InputField/InputField";
import AllChallangeOrder from "../../components/common/Popup/ChallengeOrder/ChallengeOrder";
import AllTitleOrder from "../../components/common/Popup/TitleOrder/TitleOrder";
import uplod from '../../assets/images/uploadIcon.svg';
import fileUploads from '../../assets/images/fileuplod.svg';
import { hideLoader, setCookie, showLoader } from '../../helpers';
import { fileUpload } from '../../services/api/upload';
import { toast } from 'react-toastify';
import { createChallenges } from '../../services/api/common';

export const ChallengeScoresNew = () => {
    const { control, formState: { errors }, handleSubmit, register, reset } = useForm();
    const [questionType, setQuestionType] = useState('Image');
    const [answerCount, setAnswerCount] = useState(4);
    const [isCheckOrder, setisCheckOrder] = useState(false);
    const [isTitleOrder, setisTitleOrder] = useState(false);
    const [challengeTitle, setChallengeTitle] = useState();
    const [answerImageURL, setAnswerImageURL] = useState({});
    const [answerType, setAnswerType] = useState('Text');
    const [isRefresh, setRefresh] = useState(false)
    const videoRef = useRef();
    const audioRef = useRef();

    useEffect(() => { // this hook will get called everytime when isRefresh has changed
        // perform some action which will get fired everytime when isRefresh gets updated
        videoRef.current?.load();
        audioRef.current?.load();
    }, [isRefresh])

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const AnswerFileUpload = async (file) => {
        let content = new FormData();
        content.append('fileKey', file);
        const {
            data: {
                data
            }
        } = await fileUpload(content);
        return data?.fileKey
    }
    const constructParams = async (data, submitData) => {
        for (let key in data) {
            if (key.includes('answer') && data[key]) {
                if (answerType === 'Text') {
                    submitData[capitalizeFirstLetter(key)] = data[key];
                    delete data[key];
                } else {
                    if (key === "answerType" || key === "answerCount") {
                        submitData[key] = data[key]
                    } else {
                        //  for (let i = 0; i < answerCount; i++) {
                        // if (data[key]) {
                        if (data[key]) {
                            submitData[capitalizeFirstLetter(key.slice(0, -5))] = await AnswerFileUpload(data[key])
                        }
                        // }
                        //  }
                    }
                }
            }
            if (key === 'challengeImage' && data[key]) {
                submitData[key] = await AnswerFileUpload(data[key])
            }
        }

        return submitData;
    }


    const submit = async (values) => {
        try {
            const { answerCount, answerType,
                challengeOrder, challengeTitle, challengeType, coinsToEarn, correctAnswer, imageQuestionContent,
                questionTime, questionType, titleOrder, userLevel, userTarget } = values;
            let isValidCorrectAnswer = false
            if (answerType === 'Text') {
                isValidCorrectAnswer = values['answer' + correctAnswer] === ""
            }
            if (isValidCorrectAnswer) {
                toast.error(`Please update answer as per correct answer`);
            } else {
                showLoader();
                let submitData = {
                    challengeType: challengeType,
                    challengeTitle: challengeTitle,
                    titleOrder: titleOrder,
                    Level: userLevel,
                    challengeOrder: challengeOrder,
                    questionType: questionType,
                    questionTitle: imageQuestionContent,
                    answersType: answerType,
                    answersCount: answerCount,
                    secondsToAnswer: questionTime,
                    correctAnswer: correctAnswer,
                    userTarget: userTarget,
                    coinsToEarn: coinsToEarn
                }
                submitData = await constructParams(values, submitData)
                const {
                    data: {
                        data,
                        message
                    }
                } = await createChallenges(submitData);
                hideLoader();
                toast.success(message ? message : "Success");
                setTimeout(() => {
                    window.location.href = '/challengescores'

                }, 1000);
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }
    const PlaceHolder = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth"];

    const checkFileType = (file) => {
        if (file.type.match('image.*'))
            return 'Image';

        if (file.type.match('video.*'))
            return 'Video';

        if (file.type.match('audio.*'))
            return 'Audio';

        return 'other';
    }

    const handleFileUpload = async (file, id) => {
        try {
            const fileType = checkFileType(file)
            if (fileType === questionType) {
                let ansImageUrl = answerImageURL;
                ansImageUrl[id] = URL.createObjectURL(file)
                setAnswerImageURL(ansImageUrl);
                setRefresh(!isRefresh)
                hideLoader();
            } else {
                if (id === 'challengeImage') {
                    reset({ id: "" });
                    toast.error("Please upload file as per the question type");
                }
            }

        } catch (error) {
            // toast.error("Failed");
        }
    }

    const ShowAnswers = () => {
        let answerHtml = [];
        let spaceStyle = { 2: 6, 3: 4, 4: 3, 5: 4, 6: 4, 7: 4, 8: 4 }
        for (let i = 0; i < answerCount; i++) {
            let index = i + 1;
            let inputValidataion = "Required"
            if (answerType === 'Text' && answerCount > 3 && i === answerCount - 1) {
                inputValidataion = false;
            }
            if (answerType === 'Image' && answerCount > 3 && `answer${index}Image` !== "") {
                inputValidataion = false;
            }
            if (answerType === 'Text') {
                answerHtml.push(<Col md={spaceStyle[answerCount]} className={styles.inNew} key={index}>
                    <div className={styles.FormGroup}>
                        <label>Answer {index}</label>
                        {/* <input  placeholder="Your first answer here"/> */}
                        <InputField
                            className={styles.formControl}
                            name={`answer` + index}
                            type="text"
                            placeholder={`Your ${PlaceHolder[i]} answer here`}
                            register={register}
                            validation={{
                                required: inputValidataion,
                            }}
                            error={errors["answer" + index]}
                        />
                    </div>
                </Col>)
            } else if (answerType === 'Image') {
                answerHtml.push(<Col md={spaceStyle[answerCount]} className={styles.FormGroup} key={index}>
                    <label>Answer {index}</label>
                    <div className={styles.optionImage} id={`showAnsImg${index}`}>
                        <img src={answerImageURL[`answer${index}Image`]} style={{ height: answerImageURL[`answer${index}Image`] ? "75px" : "0px" }} />

                    </div>
                    <div className={styles.newUplod}>
                        <img src={fileUploads} />
                        <div className={styles.fileUp}>
                            <Controller
                                name={`answer${index}Image`}
                                control={control}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <input
                                        className={styles.fileUploadNew}
                                        type="file"
                                        accept="image/*"
                                        id={`answer${index}Image`}
                                        // onChange={(e) => handleFileUpload(e.target.files[0], e.target.id)}
                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: inputValidataion }}
                                onFocus={errors["answer" + index + "Image"]?.ref}
                            />
                            <h4>Upload your files</h4>
                        </div>
                    </div>
                    {errors && errors["answer" + index + "Image"] && <span className="errorMessage" >Required</span>}
                </Col>)
            }
        }
        return answerHtml;

    }

    const handleQuesType = (val) => {
        setQuestionType(val)
        if (answerImageURL.challengeImage) {
            answerImageURL.challengeImage = ""
        }
    }

    return <section className={styles.ChallengeScores}>
        <Container fluid className={styles.bghead}>
            <Row>
                <Col md={12}>
                    <div className={styles.headingSec}>
                        <h3>Challenges & Scores</h3>
                        <p>Please fill all the information for the question</p>
                    </div>
                </Col>
            </Row>
        </Container>
        <Container fluid className={styles.bgcon}>
            <Row>
                <Col md={2}>
                    <div className={styles.newinput}>
                        <div className={styles.FormGroup}>
                            <label>Challenge Type<span>*</span></label>
                            <Controller
                                name="challengeType"
                                control={control}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        isSearchable={true}
                                        options={challengeTypeOption}
                                        placeholder="Enter challenge type"
                                        className={styles.formControl}
                                        error={errors?.challengeType}
                                        onChange={e => onChange(e.value)}
                                        register={register}
                                        inputRef={ref}
                                    />
                                )}
                                rules={{ required: 'Required' }}
                                onFocus={errors.challengeType?.ref}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.newinput}>
                        <div className={styles.FormGroup}>
                            <label>Challenge Title<span>*</span></label>
                            <InputField
                                className={styles.newFormcontrol}
                                name="challengeTitle"
                                type="text"
                                register={register}
                                placeholder="Enter Challenge Title"
                                onChange={(e) => setChallengeTitle(e.target.value)}
                                validation={{
                                    required: 'Required'
                                }}
                                error={errors?.challengeTitle}
                            />

                        </div>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.newinput}>
                        <div className={styles.FormGroup}>
                            <label>User Target<span>*</span></label>
                            <Controller
                                name="userTarget"
                                control={control}
                                defaultValue={userTargetOption[1].value}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        isSearchable={true}
                                        options={userTargetOption}
                                        defaultValue={userTargetOption[1]}
                                        placeholder="Enter user target"
                                        className={styles.formControl}
                                        onChange={(e) => onChange(e.value)}
                                        error={errors?.userTarget}
                                        inputRef={ref}
                                        rules={{ required: 'Required' }}
                                    />
                                )}

                                onFocus={errors.userTarget?.ref}
                            />
                        </div>
                    </div>
                </Col>
                <Col md={2}>
                    <div className={styles.newinput}>
                        <div className={styles.FormGroup}>
                            <label>Question Type<span>*</span></label>
                            <Controller
                                name="questionType"
                                control={control}
                                defaultValue={questionTypeOption[1].value}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <Dropdown
                                        options={questionTypeOption}
                                        placeholder="Choose question type"
                                        defaultValue={questionTypeOption[1]}
                                        className={styles.formControl}
                                        onChange={(e) => onChange(e.value, handleQuesType(e.value))}
                                        error={errors?.questionType}
                                        inputRef={ref}
                                        rules={{ required: 'Required' }}
                                    />
                                )}

                                onFocus={errors.questionType?.ref}
                            />
                        </div>
                    </div>
                </Col>
                {questionType !== 'Text' &&
                    <Col md={4}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>Text for the {questionType}</label>
                                <InputField
                                    className={styles.newFormcontrol}
                                    name="imageQuestionContent"
                                    type="text"
                                    placeholder="Place your question content here"
                                    register={register}
                                    validation={{
                                        required: 'Required'
                                    }}
                                    error={errors?.imageQuestionContent}
                                />
                            </div>
                        </div>
                    </Col>
                }
                {/* 
                {ShowAnswers()} */}
            </Row>
            <Row>
                <Col md={7}>
                    <Row>
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Level<span>*</span></label>
                                    <Controller
                                        name="userLevel"
                                        control={control}
                                        defaultValue={userLevelOption[0].value}
                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                            <Dropdown
                                                options={userLevelOption}
                                                placeholder="Enter user level"
                                                defaultValue={userLevelOption[0]}
                                                className={styles.formControl}
                                                onChange={(e) => onChange(e.value)}
                                                error={errors?.userLevel}
                                                inputRef={ref}
                                                rules={{ required: 'Required' }}
                                            />
                                        )}

                                        onFocus={errors.userLevel?.ref}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Question Time<span>*</span></label>
                                    <Controller
                                        name="questionTime"
                                        control={control}
                                        defaultValue={questionTimeOption[0].value}
                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                            <Dropdown
                                                options={questionTimeOption}
                                                placeholder="Enter question time"
                                                defaultValue={questionTimeOption[0]}
                                                className={styles.formControl}
                                                onChange={(e) => onChange(e.value)}
                                                error={errors?.questionTime}
                                                inputRef={ref}
                                                rules={{ required: 'Required' }}
                                            />
                                        )}

                                        onFocus={errors.questionTime?.ref}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Coins To Earn<span>*</span></label>
                                    <InputField
                                        className={styles.newFormcontrol}
                                        name="coinsToEarn"
                                        type="text"
                                        placeholder="Score for this question"
                                        register={register}
                                        validation={{
                                            required: 'Required',
                                            pattern: {
                                                value: /^(?!0+$)[0-9]{1,10}$/,
                                                message: 'Score can not be 0 and aplhabets'
                                            }
                                        }}
                                        error={errors?.coinsToEarn}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <AllTitleOrder
                        isOpen={isTitleOrder}
                        //  data={jobsData}
                        handleClose={() => setisTitleOrder(false)}

                    />
                    <Row>
                        <Col md={6}>
                            <div className={styles.newButton} >
                                <div className={styles.FormGroup}>
                                    <label>Title Order<span>*</span></label>
                                    <InputField
                                        className={styles.formControl}
                                        name="titleOrder"
                                        type="text"
                                        placeholder="Enter title order"
                                        register={register}
                                        validation={{
                                            required: 'Required',
                                            pattern: {
                                                value: /^(?!0+$)[0-9]{1,10}$/,
                                                message: 'Title order can not be 0 and aplhabets'
                                            }
                                        }}
                                        error={errors?.titleOrder}
                                    />
                                </div>
                                <button className={styles.chlButton} onClick={() => setisTitleOrder(true)}>Check order</button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={styles.newButton} >
                                <div className={styles.FormGroup}>
                                    <label>Challenge Order<span>*</span></label>
                                    <InputField
                                        className={styles.formControl}
                                        name="challengeOrder"
                                        type="text"
                                        placeholder="Enter challenge order"
                                        register={register}
                                        validation={{
                                            required: 'Required',
                                            pattern: {
                                                value: /^(?!0+$)[0-9]{1,10}$/,
                                                message: 'Challenge order can not be 0 and aplhabets'
                                            }
                                        }}
                                        error={errors?.challengeOrder}
                                    />
                                </div>
                                <button className={styles.chlButton} onClick={() => setisCheckOrder(true)}>Check challenge order</button>
                            </div>
                        </Col>
                    </Row>
                    <AllChallangeOrder
                        isOpen={isCheckOrder}
                        data={challengeTitle}
                        handleClose={() => setisCheckOrder(false)}

                    />
                    <Row>
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Answer Type<span>*</span></label>
                                    <Controller
                                        name="answerType"
                                        control={control}
                                        defaultValue={answerTypeOption[1].value}
                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                            <Dropdown
                                                options={answerTypeOption}
                                                placeholder="Choose answer type"
                                                defaultValue={answerTypeOption[1]}
                                                className={styles.formControl}
                                                onChange={(e) => onChange(e.value, setAnswerType(e.value))}
                                                error={errors?.answerType}
                                                inputRef={ref}
                                                rules={{ required: 'Required' }}
                                            />
                                        )}

                                        onFocus={errors.answerType?.ref}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Answers Count<span>*</span></label>
                                    <Controller
                                        name="answerCount"
                                        control={control}
                                        defaultValue={answerCountOption[2].value}
                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                            <Dropdown
                                                options={answerCountOption}
                                                placeholder="Choose answer count"
                                                defaultValue={answerCountOption[2]}
                                                className={styles.formControl}
                                                onChange={(e) => onChange(e.value, setAnswerCount(e.value))}
                                                error={errors?.answerCount}
                                                inputRef={ref}
                                                rules={{ required: 'Required' }}
                                            />
                                        )}

                                        onFocus={errors.answerCount?.ref}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Correct Answer<span>*</span></label>
                                    <Controller
                                        name="correctAnswer"
                                        control={control}
                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                            <Dropdown
                                                options={correctAnswerOption}
                                                placeholder="Choose correct answer"
                                                className={styles.formControl}
                                                onChange={(e) => onChange(e.value)}
                                                error={errors?.correctAnswer}
                                                inputRef={ref}
                                            />
                                        )}
                                        rules={{ required: 'Required' }}
                                        onFocus={errors.correctAnswer?.ref}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={5}>
                    {questionType == 'Text' &&
                        <div className={styles.contIn}>
                            <div className={styles.FormGroup}>
                                <label>Challenge question</label>
                                <InputField
                                    className={styles.newFormcontrol}
                                    name="imageQuestionContent"
                                    type="text"
                                    placeholder="Place your question content here"
                                    register={register}
                                    validation={{
                                        required: 'Required'
                                    }}
                                    error={errors?.imageQuestionContent}
                                />
                            </div>
                        </div>
                    }
                    {questionType != 'Text' &&
                        <div className={styles.uplodImage}>
                            <div className={styles.bgupload}>
                                <div className={styles.imgUploaded}>
                                    {questionType === 'Image' && <img src={answerImageURL.challengeImage} alt="" id="representativeImage" />}
                                    {(questionType === 'Video' && answerImageURL.challengeImage) && <video width="320" height="240" controls autoPlay={true} muted playsInline ref={videoRef}>
                                        <source src={answerImageURL.challengeImage} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>}
                                    {(questionType === 'Audio' && answerImageURL.challengeImage) && <audio controls ref={audioRef}>
                                        <source src={answerImageURL.challengeImage} type="audio/mp3" />
                                        Your browser does not support the audio tag.
                                    </audio>}
                                    {/* <img src={uplod}/> */}

                                </div>
                                <div className={styles.uploadnew}>
                                    <img src={uplod} />
                                    <h3>Upload your {questionType}</h3>
                                    <div className={styles.upLoadSec}>
                                        <p className={styles.broButton}>Browse file</p>
                                        <Controller
                                            name="challengeImage"
                                            control={control}

                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <input
                                                    className={styles.inputImage}
                                                    type="file"
                                                    accept="image/*, video/*,.mp3,audio/*"
                                                    id="challengeImage"
                                                    onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                />
                                            )}
                                            rules={{ required: true }}
                                            onFocus={errors.challengeImage?.ref}
                                        />
                                    </div>
                                </div>
                            </div>
                            {errors && errors.challengeImage && <span className="errorMessage">Required</span>}
                        </div>
                    }
                </Col>
            </Row>
            {answerType === 'Text' ? <Row className={styles.twoText}>
                <Col md={12}>
                    <Row className={styles.boxBor}>
                        <ShowAnswers />
                    </Row>
                </Col>
            </Row> :
                <Row className={styles.twoCount}>
                    <Col md={12}>
                        <Row className={styles.boxBor}>
                            <ShowAnswers />
                        </Row>
                    </Col>
                </Row>}
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            <div className={styles.btnstyle}>
                                <button className={styles.resetCha} onClick={() => window.location.href = '/challengescores'}>Reset Challenges</button>
                                <button className={styles.postchl} onClick={handleSubmit(submit)}>Post Challenges</button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>
}