import React from 'react';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';

//components
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';

import Login from '../components/Login/Login';
import ProtectedRoute from './ProtectedRoute';
import ChallengeScores from '../components/Challenges&Scores/ChallengesScores';
import TitleOrder from '../components/common/Popup/TitleOrder/TitleOrder';
import ChallengeOrder from '../components/common/Popup/ChallengeOrder/ChallengeOrder';
import { ChallengeScoresNew } from '../components/Challenges&Scores/ChallengeScoresNew'
import AllChallenges from '../components/AllChallenges/AllChallenges';

function RouterComponent() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/challengescores' element={<ProtectedRoute><ChallengeScoresNew /></ProtectedRoute>} />
                <Route path='/editchallenges' element={<ProtectedRoute><AllChallenges /></ProtectedRoute>} />
                <Route path='/challengescoresnew' element={<ProtectedRoute><ChallengeScoresNew /></ProtectedRoute>} />
                <Route path='/titleorder' element={<TitleOrder />} />
                <Route path='/challengeorder' element={<ChallengeOrder />} />
                <Route path='/login' element={<Login />} />
            </Routes>
        </Router>
    );
}

export default RouterComponent;