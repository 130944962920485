import { useState, useEffect } from "react";
import { Col, Container, FormGroup, Row } from "react-bootstrap";
import styles from './Popup.module.scss';
import saveJob from '../../../assets/images/savejobimg.svg';
import closeJob from '../../../assets/images/closeIcon.svg';
import InputField from "../../common/InputField/InputField";
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Popup({
  children,
  isOpen,
  isClose
 }) {

  return (
    <div className={styles.popupBox}>
      <Modal className={styles.saveJobpop} open={isOpen} onClose={isClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className={styles.saveJobCont}>
          {children}
        </Box>
      </Modal>
  </div>
  );
}






