import { axiosInstance } from "../axiosInstance";
import { endPoints } from "../endPoints";

export const login = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.common.login,
    data
  });
};

export const getCandidatesByStatus = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getCandidateByStatus,
    params
  });
};

export const updateCandidateJobStatus = (data) => {
  return axiosInstance({
    method: "put",
    url: endPoints.common.updateCandidateJobStatus,
    data
  });
};

export const getCity = (data = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.cityListByState,
    data
  });
};

export const getExperienceList = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getExperienceList,
    params
  });
};

export const getEducationList = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getEducationList,
    params
  });
};

export const getPositionList = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getPositionList,
    params
  });
};

export const getSpecializationList = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getSpecializationList,
    params
  });
};

export const rejectJob = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.common.rejectJob,
    data
  });
};

export const getAllInterview = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getAllInterview,
    params
  });
};

export const rescheduleInterview = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.common.rescheduleInterview,
    data
  });
};

export const getIndustryList = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getIndustryList,
    params
  });
};

export const updatePreferences = (data) => {
  return axiosInstance({
    method: "put",
    url: endPoints.common.updatePreferences,
    data
  });
};

export const fetchNewCareerTips = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.fetchNewCareerTips,
    params
  });
};

export const getSignedUrl = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: `${endPoints.common.getSignedUrl}/${params.id}`
  });
};

export const updateJobStatus = (data) => {
  return axiosInstance({
    method: "put",
    url: endPoints.common.updateJobStatus,
    data
  });
};

export const checkExistingCandidate = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.common.checkExistingCandidate,
    data
  });
};

export const createJobMatch = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.common.createJobMatch,
    data
  });
};

export const createChallenges = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.common.createChallenges,
    data
  });
};

export const getAllChallenges = (data) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getAllChallenges,
    data
  });
};

export const updateChallengesById = (data) => {
  return axiosInstance({
    method: "post",
    url: endPoints.common.updateChallengesById,
    data
  });
};

export const titleOrder = (data) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getTitleOrder,
    data
  });
};

// export const challengeOrder = (data) => {
//   return axiosInstance({
//     method: "get",
//     url: endPoints.common.getChallengeOrder,
//     data
//   });
// };
export const challengeOrder = (params = {}) => {
  return axiosInstance({
    method: "get",
    url: endPoints.common.getChallengeOrder,
    params
  });
};
