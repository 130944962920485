import { Col, Row } from "react-bootstrap";
import React, { Component, useEffect, useState } from "react";

import Button from '@mui/material/Button';
import styles from './ChallengeOrder.module.scss';
import closeJob from '../../../../assets/images/closeIcon.svg';
import cabmenu from '../../../../assets/images/cabmenu.svg';
import ReactHover, { Trigger, Hover } from "react-hover";
import HoverComponent from "../../../../HoverComponent";
import TriggerComponent from "../../../../TriggerComponent";
import imgVIEW from '../../../../assets/images/eye.svg';
import Popup from "../Popup";
import { challengeOrder } from "../../../../services/api/common"
import { getSignedUrl } from "../../../../services/api/common"
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



export default function ChallengeOrder({
    isOpen,
    data,
    handleClose
}) {
    const [challengeOrderData, setChallengeOrderData] = useState([]);
    const [challengeImage, setChallengeImage] = useState();
    let sNo = 1
    useEffect(() => {

        getchallengeOrder(data);

    }, [data])

    const getchallengeOrder = async (challengeTitle) => {

        try {
            let params = {
                challengeTitle
            }
            const {
                data: {
                    data
                }
            } = await challengeOrder(params)
            data && data.length > 0 && data.map(async (items, index) => {
                let imgFile = items.challengeImage
                items.signedURL = await getChallengeImage(imgFile);
            })
            setChallengeOrderData(data)
            console.log('challengeOrderData ', challengeOrderData)
        } catch (error) {
        }
    }
    const getChallengeImage = async (fileKey) => {
        try {
            let params = {
                id: fileKey
            }
            const {
                data: {
                    appStatus
                }
            } = await getSignedUrl(params)
            console.log(appStatus)
            return appStatus;
        } catch (error) {
        }
    }
    return (
        <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
                <img src={closeJob} onClick={handleClose} />
                <Col md={12} className={styles.newBg}>
                    <Row className={styles.marHead}>
                        <Col md={12}>
                            <div className={styles.headReject}>
                                <h3>Challenge Order <span></span></h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className={styles.bgRow}>
                        <Col md={12}>
                            <div className={styles.tableNew}>
                                <table>
                                    <thead className={styles.bgHead}>
                                        <tr className={styles.headNew}>
                                            <th className={styles.snWidth} scope="col">S No.</th>
                                            <th className={styles.headWidth} scope="col">Challenge Title</th>
                                            <th className={styles.headWidth} scope="col">Title Order</th>
                                            <th className={styles.headWidth} scope="col">Question Title</th>
                                            <th className={styles.headWidth} scope="col">Challenge Image</th>
                                            <th className={styles.headWidth} scope="col">Challenge Order</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </Col>

                    </Row>
                    <Row className={styles.marSIde}>
                        <Col md={12}>
                            <table>
                                <tbody className={styles.bgdata}>

                                    {challengeOrderData && challengeOrderData.length > 0 && challengeOrderData.map((items, index) => {
                                        try {

                                            return (
                                                <tr className={styles.dataNew}>
                                                    <td key={index} className={styles.snWidth}>{sNo++}</td>
                                                    <td key={index} className={styles.headWidth}>{items.challengeTitle}</td>
                                                    <td key={index} className={styles.headWidth}>{items.titleOrder}</td>
                                                    <td key={index} className={styles.headWidth}>{items.questionTitle}</td>
                                                    <td key={index} className={styles.headWidth}><img src={items.signedURL}></img></td>
                                                    <td key={index} className={styles.headWidth}>{items.challengeOrder}</td>
                                                </tr>
                                            )
                                        }
                                        catch (error) {
                                            console.log(error)
                                        }
                                    })}
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <Row className={styles.marbtns}>
                        <Col md={12}>
                            <div className={styles.btnNew}>
                                <div className={styles.btnCancel}>
                                    <div className={styles.btnEff}></div>
                                    <button onClick={handleClose}>Back <span></span></button>
                                </div>
                                <div className={styles.btnReset}>
                                    <div className={styles.effReset}></div>
                                    <button onClick={handleClose} type="submit">Done<span></span></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Popup>
    );
}









