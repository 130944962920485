import React, { Component } from "react";
import "../src/component.scss";
import imgVIEW from './assets/images/eye.svg';

export default class TriggerComponent extends Component {
  render() {
    return (
      <h1 className="trigger">
        Image  <img src={imgVIEW}/>
      </h1>
    );
  }
}
