import { Col, Row } from "react-bootstrap";
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import styles from './TitleOrder.module.scss';
import closeJob from '../../../../assets/images/closeIcon.svg';
import Popup from "../Popup";
import {titleOrder} from "../../../../services/api/common"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function TitleOrder({
  isOpen,
  handleClose
}) {
  
  const [titleOrderData, setTitleOrderData] = useState([]);

  useEffect(() => {
    getTitleOrder();
   
},[])
const getTitleOrder = async () => {
  try {
      const {
          data: {
              data
          }
      } = await titleOrder();
      setTitleOrderData(data)
      //console.log(titleOrderData)
  } catch (error) {
  }
}

  return (
          <Popup isOpen={isOpen} isClose={handleClose}>
            <div className={styles.newCss}>
            <img src={closeJob} onClick={handleClose}/>
            <Col md={12} className={styles.newBg}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headReject}>
                            <h3>Title Order <span></span></h3>
                        </div>
                    </Col> 
                </Row> 
                <Row className={styles.titleScroll}>
              
                {titleOrderData && titleOrderData.length > 0 && titleOrderData.map((items, index) => {
                                            return (
                  //console.log(item.challengeType);
                  <Col md={3}>
                    <div className={styles.boxDesi}>
                      <span>{items.titleOrder}</span>
                      <h3>{items.challengeType}<p>{items.challengeTitle}</p></h3>
                    </div>
                  </Col>
                   )
                  })}
                  
                  
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={styles.btnNew}>
                      <div className={styles.btnCancel}>
                        <div className={styles.btnEff}></div>
                        <button onClick={handleClose}>Back <span></span></button>
                      </div>
                      <div className={styles.btnReset}>
                        <div className={styles.effReset}></div>
                        <button onClick={handleClose}>Done<span></span></button>
                      </div>
                    </div>
                  </Col>
                </Row>
            </Col>
            </div>
        </Popup>
  );
}









  