import { style } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import styles from './Login.module.scss';
import CompanyLogo from '../../assets/images/logoNew.svg';
import { toast } from 'react-toastify';
import { showLoader, hideLoader, setCookie } from '../../helpers/index';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

function Login(){

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  let userNameMessage = {
    required: 'User Name  is required'
  }
  let passwordMessage = {
    required: 'Password  is required'
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    showLoader();
    if(userName == 'JobsgaarAdmin' && password == 'Jobsgaar@#54321'){
      hideLoader();
      toast.success("Login Successfull");
      const min = 1;
      const max = 100;
      const refreshToken = min + Math.random() * (max - min);
      var now = new Date();
        now.setTime(now.getTime() + 8 * 3600 * 1000); //expire time 8 hours
        let cookieOptions = {
          expires: now //seconds to expire
        }
        setCookie("token", refreshToken, cookieOptions);
      navigate('/challengescores');
    }
    else{
      hideLoader();
        toast.error("Login Failed");
    }
    
  }

  return (
  <section className={styles.loginNew}>
    <Container>
        <Row>
          <Col md={12} className={styles.heightNew}>
            <div className={styles.newLogin}>
              <img src={CompanyLogo} alt='' />
              <form className={styles.formWidth} onSubmit={handleSubmit}>
                <div className={styles.FormGroup}>
                  <label>Username/Email</label>
                  <input className={styles.formControl} placeholder="Enter user name" required onChange={e => setUserName(e.target.value)}/>
                </div>
                <div className={styles.FormGroup}>
                  <label>Password</label>
                  <input className={styles.formControl} type="password" placeholder="Enter your password" required onChange={e => setPassword(e.target.value)} />
                </div>
              <button className={styles.loginButton} type="submit" >Login</button>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
  </section>
  )
};

export default Login;