import React, { useEffect, useRef, useState } from "react";
import styles from './AllChallenges.module.scss';

import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import {
    companySize,
    representativeRole,
    challengeTypeOption,
    userTargetOption,
    questionTypeOption,
    userLevelOption,
    questionTimeOption,
    answerTypeOption,
    answerCountOption,
    correctAnswerOption
} from '../../common/DropdownOptions';
import { Dropdown } from "../../components/common/Dropdown/Dropdown";
import InputField from "../../components/common/InputField/InputField";
import AllChallangeOrder from "../../components/common/Popup/ChallengeOrder/ChallengeOrder";
import AllTitleOrder from "../../components/common/Popup/TitleOrder/TitleOrder";
import uplod from '../../assets/images/uploadIcon.svg';
import fileUploads from '../../assets/images/fileuplod.svg';
import { hideLoader, setCookie, showLoader } from '../../helpers';
import { fileUpload } from '../../services/api/upload';
import { toast } from 'react-toastify';
import { getAllChallenges, getSignedUrl, updateChallengesById } from '../../services/api/common';

const AllChallenges = () => {

    const { control, formState: { errors }, handleSubmit, register, reset, setValue, getValues, resetField, unregister } = useForm();
    const [questionType, setQuestionType] = useState('Image');
    const [answerCount, setAnswerCount] = useState(0);
    const [isCheckOrder, setisCheckOrder] = useState(false);
    const [isTitleOrder, setisTitleOrder] = useState(false);
    const [challengeTitle, setChallengeTitle] = useState();
    const [challengeType, setChallengeType] = useState();
    const [answerImageURL, setAnswerImageURL] = useState({});
    const [answerType, setAnswerType] = useState('Text');
    const [isRefresh, setRefresh] = useState(false)
    const [challenges, setChallenges] = useState([]);
    const [ids, setIds] = useState([])
    const [quesList, setQuesList] = useState([]);
    const [selectedQus, setSelectedQues] = useState(0);
    const [isApiCalled, setApiCalled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const videoRef = useRef();
    const audioRef = useRef();
    const FilterbyTitleOrder = (a, b) => {
        if (a.titleOrder < b.titleOrder) {
            return -1;
        }
        if (a.titleOrder > b.titleOrder) {
            return 1;
        }
        return 0;
    }

    const getChallengeImage = async (fileKey) => {
        try {
            let params = {
                id: fileKey
            }
            const {
                data: {
                    appStatus
                }
            } = await getSignedUrl(params)
            return appStatus;
        } catch (error) {
        }
    }

    const getAllchallengesApi = async () => {
        try {
            const {
                data: {
                    data,
                    message
                }
            } = await getAllChallenges({});
            return data;

        } catch (e) {
            return []
        }
    }
    useEffect(() => {
        let getChallenges = async () => {
            if (!isApiCalled) {
                const response = await getAllchallengesApi();
                const filterData = response.sort(FilterbyTitleOrder)
                setChallenges(filterData);
                getChallengeIds(filterData);
                setApiCalled(true)
            }
            videoRef.current?.load();
            audioRef.current?.load();
        }
        getChallenges();


    }, [isRefresh])


    const setDefaultValues = async (data) => {
        showLoader();
        setQuestionType(data.questionType)
        setAnswerType(data.answersType)
        setAnswerCount(data.answersCount);
        setValue("challengeType", { label: data.challengeType, value: data.challengeType });
        setValue("challengeTitle", data.challengeTitle)
        setValue("userTarget", { label: data.userTarget, value: data.userTarget })
        setValue("questionType", { label: data.questionType, value: data.questionType });
        setValue("userLevel", { label: data.Level, value: data.Level });
        setValue("questionTime", { label: data.secondsToAnswer, value: data.secondsToAnswer });
        setValue("coinsToEarn", data.coinsToEarn);
        setValue("titleOrder", data.titleOrder);
        setValue("challengeOrder", data.challengeOrder);
        setValue("answerType", { label: data.answersType, value: data.answersType });
        setValue("answerCount", { label: data.answersCount, value: data.answersCount });
        setValue("correctAnswer", { label: data.correctAnswer, value: data.correctAnswer });
        setValue("imageQuestionContent", data.questionTitle);
        if (data.challengeType !== 'Text') {
            let ansImageUrl = answerImageURL;
            ansImageUrl.challengeImage = await getChallengeImage(data.challengeImage)

            setAnswerImageURL(ansImageUrl);
            setValue("challengeImage", data.challengeImage);
        }

        if (data.Answer1) {

            if (data.answersType !== 'Text') {
                unregister("answer1")
                setValue("answer1Image", data.Answer1);
                let img = await getChallengeImage(data.Answer1);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer1Image = img
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg1").setAttribute("src", img);

            } else {
                unregister("answer1Image")
                setValue("answer1", data.Answer1);
            }

        }
        if (data.Answer2) {

            if (data.answersType !== 'Text') {
                unregister("answer2")
                setValue("answer2Image", data.Answer2);
                let img = await getChallengeImage(data.Answer2);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer2Image = img
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg2").setAttribute("src", img);
                //setRefresh(!isRefresh);
            } else {
                unregister("answer2Image")
                setValue("answer2", data.Answer2);
            }
        }
        if (data.Answer3) {

            if (data.answersType !== 'Text') {
                unregister("answer3")
                setValue("answer3Image", data.Answer3);
                let img = await getChallengeImage(data.Answer3);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer3Image = img;
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg3").setAttribute("src", img);
                // setRefresh(!isRefresh);
            } else {
                unregister("answer3Image")
                setValue("answer3", data.Answer3);
            }
        }
        if (data.Answer4) {
            if (data.answersType !== 'Text') {
                unregister("answer4")
                setValue("answer4Image", data.Answer4);
                let img = await getChallengeImage(data.Answer4);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer4Image = img;
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg4").setAttribute("src", img);
                // setRefresh(!isRefresh)
            } else {
                unregister("answer4Image")
                setValue("answer4", data.Answer4);
            }
        }
        if (data.Answer5) {
            if (data.answersType !== 'Text') {
                unregister("answer5")
                setValue("answer5Image", data.Answer5);
                let img = await getChallengeImage(data.Answer5);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer5Image = img;
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg5").setAttribute("src", img);
                //   setRefresh(!isRefresh)
            } else {
                unregister("answer5Image")
                setValue("answer5", data.Answer5);
            }
        }
        if (data.Answer6) {

            if (data.answersType !== 'Text') {
                unregister("answer6")
                setValue("answer6Image", data.Answer6);
                let img = await getChallengeImage(data.Answer6);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer6Image = img;
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg6").setAttribute("src", img);
                //    setRefresh(!isRefresh)
            } else {
                unregister("answer6Image")
                setValue("answer6", data.Answer6);
            }
        }
        if (data.Answer7) {

            if (data.answersType !== 'Text') {
                unregister("answer7")
                setValue("answer7Image", data.Answer7);
                let img = await getChallengeImage(data.Answer7);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer7Image = img;
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg7").setAttribute("src", img);
                // setRefresh(!isRefresh)
            } else {
                unregister("answer7Image")
                setValue("answer7", data.Answer7);
            }
        }
        if (data.Answer8) {
            if (data.answersType !== 'Text') {
                unregister("answer8")
                setValue("answer8Image", data.Answer8);
                let img = await getChallengeImage(data.Answer8);
                let ansImageUrl = answerImageURL;
                ansImageUrl.answer8Image = img;
                setAnswerImageURL(ansImageUrl);
                document.getElementById("showAnsImg8").setAttribute("src", img);

            } else {
                unregister("answer8Image")
                setValue("answer8", data.Answer8);
            }
        }
        setRefresh(!isRefresh)
        hideLoader();
    }

    const getChallengeIds = (data) => {

        try {
            let challageIds = [];
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    setSelectedQues(data[i])
                    setDefaultValues(data[i]);
                }
                let ques = {};
                const keys = Object.keys(data[i]);
                if (data[i]['_id']) {
                    challageIds.push(data[i]['_id']['$oid'])
                }
                if (data[i]['questionTitle']) {
                    ques.index = i;
                    ques.title = data[i]['questionTitle'];
                    ques.challengeOrder = data[i]['challengeOrder'];
                    ques.titleOrder = data[i]['titleOrder'];
                }
                quesList.push(ques);
            }
            setQuesList(quesList)
            setIds(challageIds);
            //setDefaultValues(challenges);
        } catch (e) {
            console.log("ERRROR-------->", e)
        }
    }


    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const AnswerFileUpload = async (file) => {
        if (file.name) {
            let content = new FormData();
            content.append('fileKey', file);
            const {
                data: {
                    data
                }
            } = await fileUpload(content);
            return data?.fileKey
        }
    }
    const constructParams = async (data, submitData) => {
        let index = 1;
        for (let key in data) {
            index++;
            if (key.includes('answer') && data[key]) {

                if (answerType === 'Text') {
                    submitData[capitalizeFirstLetter(key)] = data[key]
                    // for (let i = 1; i <= answerCount; i++) {
                    //     submitData['Answer' + i] = data[key]
                    // }
                } else {
                    let keyVal = key.replace("Image", "")
                    submitData[capitalizeFirstLetter(keyVal)] = data[key].name ? await AnswerFileUpload(data[key]) : data[key]
                    // for (let i = 1; i <= answerCount; i++) {
                    //     console.log("keykeykeykey-------------->", key, data[key])
                    //     if ((key === `answer${i}Image` || key === 'challengeImage') && data[key]) {
                    //         console.log("1111111---------------")
                    //          submitData['Answer' + i] = data[key].name ? await AnswerFileUpload(data[key]) : data[key]
                    //     } else {
                    //         console.log("22222222---------------")
                    //         let fileKey = await AnswerFileUpload(data[key]);
                    //         submitData[key] = fileKey
                    //     }
                    // }

                    // for (let i = 1; i <= answerCount; i++) {
                    //     console.log("1111111---------------")
                    //     submitData[`answer${i}Image`] = data[key].name ? await AnswerFileUpload(data[key]) : data[key]

                    // }
                }
            }
        }

        return submitData;
    }
    const submit = async (values) => {
        try {
            const { answerType, answerCount,
                challengeOrder, challengeTitle, challengeType, coinsToEarn, correctAnswer, imageQuestionContent,
                questionTime, questionType, titleOrder, userLevel, userTarget } = values;
            // return false;
            let isValidCorrectAnswer = false
            if (answerType === 'Text') {
                isValidCorrectAnswer = values['answer' + correctAnswer] === ""
            }
            if (isValidCorrectAnswer) {
                toast.error(`Please update answer as per correct answer`);
            } else {
                showLoader();
                let submitData = {
                    _id: selectedQus._id,
                    challengeType: values.challengeType ? values.challengeType.value : challengeType,
                    challengeTitle: challengeTitle,
                    titleOrder: titleOrder,
                    Level: values["userLevel"] ? values.userLevel.value : userLevel,
                    challengeOrder: challengeOrder,
                    questionType: values["questionType"] ? values.questionType.value : questionType,
                    questionTitle: imageQuestionContent,
                    answersType: values["answerType"] ? values.answerType.value : answerType,
                    challengeImage: values.challengeImage === selectedQus.challengeImage ? values.challengeImage : await AnswerFileUpload(values.challengeImage),
                    answersCount: values["answerCount"] ? values.answerCount.value : answerCount,
                    secondsToAnswer: values["questionTime"] ? values.questionTime.value : questionTime,
                    correctAnswer: values["correctAnswer"] ? values.correctAnswer.value : correctAnswer,
                    userTarget: values["userTarget"] ? values.userTarget.value : userTarget,
                    coinsToEarn: coinsToEarn
                }
                let dupValues = values;
                delete dupValues.answerCount;
                delete dupValues.answerType;
                submitData = await constructParams(dupValues, submitData)
                const {
                    data: {
                        data,
                        message
                    }
                } = await updateChallengesById(submitData);
                hideLoader();
                toast.success(message ? message : "Success");
                const response = await getAllchallengesApi();
                const filterData = response.sort(FilterbyTitleOrder)
                setDefaultValues(filterData[selectedIndex]);
                setChallenges(filterData);
                quesList[selectedIndex].title = response[selectedIndex].questionTitle;
            }
        } catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }
    const PlaceHolder = ["first", "second", "third", "fourth", "fifth", "sixth", "seventh", "eighth"];
    const handleFileUpload = async (file, id) => {
        try {
            setValue(id, file)
            setAnswerImageURL({});
            let ansImageUrl = answerImageURL;
            ansImageUrl[id] = URL.createObjectURL(file)
            setAnswerImageURL(ansImageUrl);
            setRefresh(!isRefresh)
            hideLoader();
        } catch (error) {
            toast.error("Failed");
        }
    }
    const ShowAnswers = () => {
        let answerHtml = [];
        let spaceStyle = { 2: 6, 3: 4, 4: 3, 5: 4, 6: 4, 7: 4, 8: 4 }
        for (let i = 0; i < answerCount; i++) {
            let index = i + 1;
            let inputValidataion = "Required"
            if (answerType === 'Text' && answerCount > 3 && i === answerCount - 1) {
                inputValidataion = false;
            }
            if (answerType === 'Text') {
                answerHtml.push(<Col md={spaceStyle[answerCount]} className={styles.inNew} key={index}>
                    <div className={styles.FormGroup}>
                        <label>Answer {index}</label>
                        {/* <input  placeholder="Your first answer here"/> */}
                        <InputField
                            className={styles.formControl}
                            name={`answer` + index}
                            type="text"
                            placeholder={`Your ${PlaceHolder[i]} answer here`}
                            register={register}
                            validation={{
                                required: inputValidataion,
                            }}
                            error={errors["answer" + index]}
                            value={getValues("answer" + index)}
                        />
                    </div>
                </Col>)
            } else if (answerType === 'Image') {
                answerHtml.push(<Col md={spaceStyle[answerCount]} className={styles.FormGroup} key={index}>
                    <label>Answer {index}</label>
                    <div className={styles.optionImage}>
                        <img id={`showAnsImg${index}`} src={answerImageURL[`answer${index}Image`]} style={{ height: answerImageURL[`answer${index}Image`] ? "75px" : "0px" }} />

                    </div>
                    <div className={styles.newUplod}>
                        <img src={fileUploads} />
                        <div className={styles.fileUp}>
                            <Controller
                                name={`answer${index}Image`}
                                control={control}
                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                    <input
                                        className={styles.fileUploadNew}
                                        type="file"
                                        accept="image/*"
                                        id={`answer${index}Image`}
                                        onChange={(e) => { setValue(`answer${index}Image`, e.target.files[0]); handleFileUpload(e.target.files[0], e.target.id) }}
                                        ref={ref}
                                    />
                                )}
                                rules={{ required: inputValidataion }}
                                onFocus={errors["answer" + index + "Image"]?.ref}
                            />
                            <h4>Upload your files</h4>
                        </div>
                    </div>
                    {errors && errors["answer" + index + "Image"] && <span className="errorMessage" >Required</span>}
                </Col>)
            }
        }
        return answerHtml;

    }

    const editQuestion = (e, index) => {
        e.preventDefault();
        setSelectedIndex(index)
        setSelectedQues(challenges[index])
        setDefaultValues(challenges[index])
    }

    const previous = (e) => {
        e.preventDefault();
        if (selectedIndex === 0) {
            toast.info("No more previous questions")
        } else {
            if (e.target.name !== 'next') {
                const prevIndex = selectedIndex - 1;
                setDefaultValues(challenges[prevIndex])
                setSelectedQues(challenges[prevIndex])
                setSelectedIndex(prevIndex)
            }
        }


    }
    const next = (e) => {
        e.preventDefault();
        if (selectedIndex === challenges.length - 1) {
            toast.info("No more next questions")
        } else {
            const nextIndex = selectedIndex + 1;
            setDefaultValues(challenges[nextIndex])
            setSelectedQues(challenges[nextIndex])
            setSelectedIndex(nextIndex)
        }

    }

    const handleAnswerCount = e => {
        setValue("answerCount", e.value)
        setValue("answerCount", e)
        setAnswerCount(e.value);
    }

    const handlechallengetype = (e) => {
        setValue('challengeType', e.value);
        //  setValue2('jobState', state);
        setValue('challengeType', e);
    }

    return <div>
        <section className={styles.ChallengeScores}>
            <Container fluid className={styles.bghead}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headingSec}>
                            <h3>Challenges & Scores</h3>
                            <p>Please fill all the information for the question</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
        {/* <div className={styles.challnegStyle}  style={{ float: "left", width: "15%", display: "flex" }}> */}
        {/* <ul className={styles.challengeUl} style={{ height: "700px", overflow: "auto" }}></ul> */}

        <div className={styles.challnegStyle} >
            <ul className={styles.challengeUl}>
                <h3>Challenges</h3>
                {quesList.map(val => {
                    return <li><a style={{ color: selectedIndex === val.index ? "#FF6600" : "#000" }} key={"ques" + val.index} href="javascript:void(0);" onClick={(e) => editQuestion(e, val.index)}>{val.title}&nbsp;

                        <span style={{ color: selectedIndex === val.index ? "#FF6600" : "#000" }} key={"cOrder" + val.index}>[ChallengeOrder: {val.challengeOrder}</span>&nbsp;

                        <span style={{ color: selectedIndex === val.index ? "#FF6600" : "#000" }} key={"tOrder" + val.index}>TitleOrder: {val.titleOrder}]</span></a></li>
                })}
            </ul>
        </div>
        <div>
            <Container fluid className={styles.bgcon}>
                <Row>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>Challenge Type<span>*</span></label>
                                <Controller
                                    name="challengeType"
                                    control={control}
                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                        <Dropdown
                                            isSearchable={true}
                                            options={challengeTypeOption}
                                            placeholder="Enter challenge type"
                                            className={styles.formControl}
                                            error={errors?.challengeType}
                                            onChange={e => handlechallengetype(e)}
                                            register={register}
                                            inputRef={ref}
                                            value={getValues("challengeType")}
                                        />
                                    )}
                                    rules={{ required: 'Required' }}
                                    onFocus={errors.challengeType?.ref}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>Challenge Title<span>*</span></label>
                                <InputField
                                    className={styles.newFormcontrol}
                                    name="challengeTitle"
                                    type="text"
                                    register={register}
                                    placeholder="Enter Challenge Title"
                                    onChange={(e) => { setChallengeTitle(e.target.value) }}
                                    validation={{
                                        required: 'Required'
                                    }}
                                    error={errors?.challengeTitle}
                                />

                            </div>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>User Target<span>*</span></label>
                                <Controller
                                    name="userTarget"
                                    control={control}
                                    defaultValue={userTargetOption[1].value}
                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                        <Dropdown
                                            isSearchable={true}
                                            options={userTargetOption}
                                            defaultValue={userTargetOption[1]}
                                            placeholder="Enter user target"
                                            className={styles.formControl}
                                            onChange={(e) => { setValue("userTarget", e.value); setValue("userTarget", e) }}
                                            error={errors?.userTarget}
                                            inputRef={ref}
                                            rules={{ required: 'Required' }}
                                            value={getValues("userTarget")}
                                        />
                                    )}

                                    onFocus={errors.userTarget?.ref}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>Question Type<span>*</span></label>
                                <Controller
                                    name="questionType"
                                    control={control}
                                    defaultValue={questionTypeOption[1].value}
                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                        <Dropdown
                                            options={questionTypeOption}
                                            placeholder="Choose question type"
                                            defaultValue={questionTypeOption[1]}
                                            className={styles.formControl}
                                            onChange={(e) => { setValue("questionType", e.value); setValue("questionType", e); setQuestionType(e.value) }}
                                            error={errors?.questionType}
                                            inputRef={ref}
                                            rules={{ required: 'Required' }}
                                            value={getValues("questionType")}
                                        />
                                    )}

                                    onFocus={errors.questionType?.ref}
                                />
                            </div>
                        </div>
                    </Col>
                    {questionType !== 'Text' &&
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Text for the {questionType}</label>
                                    <InputField
                                        className={styles.newFormcontrol}
                                        name="imageQuestionContent"
                                        type="text"
                                        placeholder="Place your question content here"
                                        register={register}
                                        validation={{
                                            required: 'Required'
                                        }}
                                        error={errors?.imageQuestionContent}
                                        value={getValues("imageQuestionContent")}
                                    />
                                </div>
                            </div>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col md={7}>
                        <Row>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Level<span>*</span></label>
                                        <Controller
                                            name="userLevel"
                                            control={control}
                                            defaultValue={userLevelOption[0].value}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={userLevelOption}
                                                    placeholder="Enter user level"
                                                    defaultValue={userLevelOption[0]}
                                                    className={styles.formControl}
                                                    onChange={(e) => { setValue("userLevel", e.value); setValue("userLevel", e.value) }}
                                                    error={errors?.userLevel}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                    value={getValues("userLevel")}
                                                />
                                            )}

                                            onFocus={errors.userLevel?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Question Time<span>*</span></label>
                                        <Controller
                                            name="questionTime"
                                            control={control}
                                            defaultValue={questionTimeOption[0].value}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={questionTimeOption}
                                                    placeholder="Enter question time"
                                                    defaultValue={questionTimeOption[0]}
                                                    className={styles.formControl}
                                                    onChange={(e) => { setValue("questionTime", e.value); setValue("questionTime", e) }}
                                                    error={errors?.questionTime}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                    value={getValues("questionTime")}
                                                />
                                            )}

                                            onFocus={errors.questionTime?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Coins To Earn<span>*</span></label>
                                        <InputField
                                            className={styles.newFormcontrol}
                                            name="coinsToEarn"
                                            type="text"
                                            placeholder="Score for this question"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^(?!0+$)[0-9]{1,10}$/,
                                                    message: 'Score can not be 0 and aplhabets'
                                                }
                                            }}
                                            error={errors?.coinsToEarn}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <AllTitleOrder
                            isOpen={isTitleOrder}
                            //  data={jobsData}
                            handleClose={() => setisTitleOrder(false)}

                        />
                        <Row>
                            <Col md={6}>
                                <div className={styles.newButton} >
                                    <div className={styles.FormGroup}>
                                        <label>Title Order<span>*</span></label>
                                        <InputField
                                            className={styles.formControl}
                                            name="titleOrder"
                                            type="text"
                                            placeholder="Enter title order"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^(?!0+$)[0-9]{1,10}$/,
                                                    message: 'Title order can not be 0 and aplhabets'
                                                }
                                            }}
                                            error={errors?.titleOrder}
                                        />
                                    </div>
                                    <button className={styles.chlButton} onClick={() => setisTitleOrder(true)}>Check order</button>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className={styles.newButton} >
                                    <div className={styles.FormGroup}>
                                        <label>Challenge Order<span>*</span></label>
                                        <InputField
                                            className={styles.formControl}
                                            name="challengeOrder"
                                            type="text"
                                            placeholder="Enter challenge order"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^(?!0+$)[0-9]{1,10}$/,
                                                    message: 'Challenge order can not be 0 and aplhabets'
                                                }
                                            }}
                                            error={errors?.challengeOrder}
                                        />
                                    </div>
                                    <button className={styles.chlButton} onClick={() => setisCheckOrder(true)}>Check challenge order</button>
                                </div>
                            </Col>
                        </Row>
                        {isCheckOrder && <AllChallangeOrder
                            isOpen={isCheckOrder}
                            data={challengeTitle}
                            handleClose={() => setisCheckOrder(false)}

                        />}
                        <Row>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer Type<span>*</span></label>
                                        <Controller
                                            name="answerType"
                                            control={control}
                                            defaultValue={answerTypeOption[1].value}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={answerTypeOption}
                                                    placeholder="Choose answer type"
                                                    defaultValue={answerTypeOption[1]}
                                                    className={styles.formControl}
                                                    onChange={(e) => { setValue("answerType", e.value); setValue("answerType", e); setAnswerType(e.value) }}
                                                    error={errors?.answerType}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                    value={getValues("answerType")}
                                                />
                                            )}

                                            onFocus={errors.answerType?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Answers Count<span>*</span></label>
                                        <Controller
                                            name="answerCount"
                                            control={control}
                                            defaultValue={answerCountOption[2].value}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={answerCountOption}
                                                    placeholder="Choose answer count"
                                                    defaultValue={answerCountOption[2]}
                                                    className={styles.formControl}
                                                    onChange={(e) => handleAnswerCount(e)}
                                                    error={errors?.answerCount}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                    value={getValues("answerCount")}
                                                />
                                            )}

                                            onFocus={errors.answerCount?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Correct Answer<span>*</span></label>
                                        <Controller
                                            name="correctAnswer"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={correctAnswerOption}
                                                    placeholder="Choose correct answer"
                                                    className={styles.formControl}
                                                    onChange={(e) => { setValue("correctAnswer", e.value); setValue("correctAnswer", e) }}
                                                    error={errors?.correctAnswer}
                                                    inputRef={ref}
                                                    value={getValues("correctAnswer")}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.correctAnswer?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        {questionType === 'Text' &&
                            <div className={styles.contIn}>
                                <div className={styles.FormGroup}>
                                    <label>Challenge question</label>
                                    <InputField
                                        className={styles.newFormcontrol}
                                        name="imageQuestionContent"
                                        type="text"
                                        placeholder="Place your question content here"
                                        register={register}
                                        validation={{
                                            required: 'Required'
                                        }}
                                        error={errors?.imageQuestionContent}
                                    />
                                </div>
                            </div>
                        }
                        {questionType !== 'Text' &&
                            <div className={styles.uplodImage}>
                                <div className={styles.bgupload}>
                                    <div className={styles.imgUploaded}>

                                        {questionType === 'Image' && <img src={answerImageURL.challengeImage} alt="" id="representativeImage" />}
                                        {questionType === 'Video' && <video width="320" height="240" controls autoPlay={true} muted playsInline ref={videoRef}>
                                            <source src={answerImageURL.challengeImage} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>}
                                        {questionType === 'Audio' && <audio controls autoPlay={true} muted playsInline ref={audioRef}>
                                            <source src={answerImageURL.challengeImage} type="audio/mp3" />
                                            Your browser does not support the audio tag.
                                        </audio>}

                                        {/* <img src={uplod}/> */}

                                    </div>
                                    <div className={styles.uploadnew}>
                                        <img src={uplod} />
                                        <h3>Upload your {questionType}</h3>
                                        <div className={styles.upLoadSec}>
                                            <p className={styles.broButton}>Browse file</p>
                                            <Controller
                                                name="challengeImage"
                                                control={control}

                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.inputImage}
                                                        type="file"
                                                        accept="image/*, video/*,.mp3,audio/*"
                                                        id="challengeImage"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.challengeImage?.ref}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {errors && errors.challengeImage && <span className="errorMessage">Required</span>}
                            </div>
                        }
                    </Col>
                    {answerType === 'Text' ? <Row className={styles.twoText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <ShowAnswers />
                            </Row>
                        </Col>
                    </Row> :
                        <Row className={styles.twoCount}>
                            <Col md={12}>
                                <Row className={styles.boxBor}>
                                    <ShowAnswers />
                                </Row>
                            </Col>
                        </Row>}
                </Row>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <div className={styles.btnstyle}>
                                    <button name="previous" className={styles.resetCha} onClick={(e) => previous(e)}>Previous</button>
                                    <button name="next" className={styles.postchl} onClick={(e) => next(e)} >Next</button>
                                    <button className={styles.Updatepostchl} onClick={handleSubmit(submit)}>update</button>
                                    <button className={styles.Newpostchl} onClick={() => window.location.href = '/challengescores'} >New</button>

                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
}

export default AllChallenges;