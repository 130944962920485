// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextArea_formControl__OFrs4 {\n  width: 100%;\n  background: none;\n  border-bottom: 1px solid #7784A7;\n  border-right: 0px;\n  border-left: 0px;\n  border-top: 0px;\n  border-radius: 0px;\n  color: #E4E6EA;\n  font-size: 13px;\n  padding-left: 10px !important;\n  outline: none;\n  height: 40px;\n  padding: 0px 5px;\n}\n\n.TextArea_worldLenth__aUZl5 {\n  position: absolute;\n  right: 19px;\n  color: #6C81D0;\n  font-size: 10px;\n  top: 35px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/TextArea/TextArea.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,gCAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,SAAA;AACJ","sourcesContent":[".formControl{\n    width: 100%;\n    background: none;\n    border-bottom: 1px solid #7784A7;\n    border-right: 0px;\n    border-left: 0px;\n    border-top: 0px;\n    border-radius: 0px;\n    color: #E4E6EA;\n    font-size: 13px;\n    padding-left: 10px !important;\n    outline: none;\n    height: 40px; \n    padding: 0px 5px;\n}\n\n.worldLenth{\n    position: absolute;\n    right: 19px;\n    color: #6C81D0;\n    font-size: 10px;\n    top: 35px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControl": "TextArea_formControl__OFrs4",
	"worldLenth": "TextArea_worldLenth__aUZl5"
};
export default ___CSS_LOADER_EXPORT___;
