// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputField_formControl__AJ4NV {\n  width: 100%;\n  background: none;\n  border-bottom: 1px solid #7784A7;\n  border-right: 0px;\n  border-left: 0px;\n  border-top: 0px;\n  border-radius: 0px;\n  font-size: 13px;\n  padding-left: 10px !important;\n  outline: none;\n  height: 40px;\n  padding: 0px 5px;\n}\n\n.InputField_field__jAEPi input::-webkit-outer-spin-button,\n.InputField_field__jAEPi input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n.InputField_field__jAEPi input[type=number] {\n  -moz-appearance: textfield;\n}", "",{"version":3,"sources":["webpack://./src/components/common/InputField/InputField.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,gBAAA;EACA,gCAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EAEA,eAAA;EACA,6BAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AAAJ;;AAKI;;EAEI,wBAAA;EACA,SAAA;AAFR;AAKI;EACI,0BAAA;AAHR","sourcesContent":[".formControl {\n    width: 100%;\n    background: none;\n    border-bottom: 1px solid #7784A7;\n    border-right: 0px;\n    border-left: 0px;\n    border-top: 0px;\n    border-radius: 0px;\n    // color: #E4E6EA;\n    font-size: 13px;\n    padding-left: 10px !important;\n    outline: none;\n    height: 40px;\n    padding: 0px 5px;\n}\n\n.field {\n\n    input::-webkit-outer-spin-button,\n    input::-webkit-inner-spin-button {\n        -webkit-appearance: none;\n        margin: 0;\n    }\n\n    input[type=number] {\n        -moz-appearance: textfield;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formControl": "InputField_formControl__AJ4NV",
	"field": "InputField_field__jAEPi"
};
export default ___CSS_LOADER_EXPORT___;
