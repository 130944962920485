import { style } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import styles from '../Challenges&Scores/ChallengesScores.module.scss'
import uplod from '../../assets/images/uploadIcon.svg';
import fileUploads from '../../assets/images/fileuplod.svg';
import { Dropdown } from "../../components/common/Dropdown/Dropdown";
import InputField from "../../components/common/InputField/InputField";
import TextArea from "../../components/common/TextArea/TextArea";
import { useForm, Controller } from "react-hook-form";
import AllChallangeOrder from "../../components/common/Popup/ChallengeOrder/ChallengeOrder";
import AllTitleOrder from "../../components/common/Popup/TitleOrder/TitleOrder";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { hideLoader, setCookie, showLoader } from '../../helpers';
import { toast } from 'react-toastify';
import { fileUpload } from '../../services/api/upload';
import { createChallenges } from '../../services/api/common';
import {
    companySize,
    representativeRole,
    challengeTypeOption,
    userTargetOption,
    questionTypeOption,
    userLevelOption,
    questionTimeOption,
    answerTypeOption,
    answerCountOption,
    correctAnswerOption
} from '../../common/DropdownOptions';
import { upload } from '@testing-library/user-event/dist/upload';




const contentStyle = {
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

function ChallengeScores() {
    const [isCheckOrder, setisCheckOrder] = useState(false);
    const [isTitleOrder, setisTitleOrder] = useState(false);
    const { register, handleSubmit, formState: { errors }, getValues, control, setValue, clearErrors } = useForm();
    const [challengeTitle, setChallengeTitle] = useState();
    const [questionType, setQuestionType] = useState('Image');
    const [answerType, setAnswerType] = useState('Text');
    const [userTarget, setUserTarget] = useState('Candidate');
    const [userLevel, setUserLevel] = useState('Easy');
    const [questionTime, setQuestionTime] = useState(30)
    const [answerCount, setAnswerCount] = useState(4);
    const [challengeImageUrl, setchallengeImageUrl] = useState();
    const [answer1ImageUrl, setAnswer1ImageUrl] = useState();
    const [answer2ImageUrl, setAnswer2ImageUrl] = useState();
    const [answer3ImageUrl, setAnswer3ImageUrl] = useState();
    const [answer4ImageUrl, setAnswer4ImageUrl] = useState();
    const [answer5ImageUrl, setAnswer5ImageUrl] = useState();
    const [answer6ImageUrl, setAnswer6ImageUrl] = useState();
    const [answer7ImageUrl, setAnswer7ImageUrl] = useState();
    const [answer8ImageUrl, setAnswer8ImageUrl] = useState();
    const handleFileUpload = async (file, id) => {

        if (id == 'answer1Image') {
            try {
                showLoader();
                setAnswer1ImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'answer2Image') {
            try {
                showLoader();
                setAnswer2ImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'answer3Image') {

            try {
                showLoader();
                setAnswer3ImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'answer4Image') {
            try {
                showLoader();
                setAnswer4ImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'answer5Image') {
            try {
                showLoader();
                setAnswer5ImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'answer6Image') {
            try {
                showLoader();
                setAnswer6ImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'answer7Image') {
            try {
                showLoader();
                setAnswer7ImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'answer8Image') {
            try {
                showLoader();
                setAnswer8ImageUrl(URL.createObjectURL(file));

                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
        if (id == 'challengeImage') {
            try {
                showLoader();
                setchallengeImageUrl(URL.createObjectURL(file));
                hideLoader();

            } catch (error) {
                toast.error("Failed");
                hideLoader();
            }
        }
    }
    useEffect(() => { // this hook will get called everytime when myArr has changed
        // perform some action which will get fired everytime when myArr gets updated

    }, [challengeImageUrl])

    const AnswerFileUpload = async (file) => {
        let content = new FormData();
        content.append('fileKey', file);
        const {
            data: {
                data
            }
        } = await fileUpload(content);
        return data.fileKey ? data.fileKey : "";
    }

    const submit = async (values) => {
        let submitData
        //console.log('hi')
        try {
            const { challengeType, imageQuestionContent, coinsToEarn
                , titleOrder, challengeOrder, correctAnswer, challengeImage, answer1, answer1Image, answer2Image, answer2, answer3, answer4, answer5, answer6, answer7, answer8 } = values;
            let isValidCorrectAnswer = false
            if (answerType === 'Text') {
                isValidCorrectAnswer = values['answer' + correctAnswer] === ""
            }
            if (isValidCorrectAnswer) {
                toast.error(`Please update answer as per correct answer`);
            } else {
                showLoader();
                if (answerType !== 'Image') {
                    if (answer5 === undefined && answer6 === undefined && answer7 === undefined && answer8 === undefined) {
                        submitData = {
                            challengeType: challengeType,
                            challengeTitle: challengeTitle,
                            titleOrder: titleOrder,
                            Level: userLevel,
                            challengeOrder: challengeOrder,
                            questionType: questionType,
                            questionTitle: imageQuestionContent,
                            answersType: answerType,
                            challengeImage: await AnswerFileUpload(challengeImage),
                            answersCount: answerCount,
                            Answer1: answer1,
                            Answer2: answer2,
                            Answer3: answer3,
                            Answer4: answer4,
                            Answer5: '',
                            Answer6: '',
                            Answer7: '',
                            Answer8: '',
                            secondsToAnswer: questionTime,
                            correctAnswer: correctAnswer,
                            userTarget: userTarget,
                            coinsToEarn: coinsToEarn
                        }

                    }
                    else if (answer6 === undefined && answer7 === undefined && answer8 === undefined) {
                        submitData = {
                            challengeType: challengeType,
                            challengeTitle: challengeTitle,
                            titleOrder: titleOrder,
                            Level: userLevel,
                            challengeOrder: challengeOrder,
                            questionType: questionType,
                            questionTitle: imageQuestionContent,
                            answersType: answerType,
                            challengeImage: await AnswerFileUpload(challengeImage),
                            answersCount: answerCount,
                            Answer1: answer1,
                            Answer2: answer2,
                            Answer3: answer3,
                            Answer4: answer4,
                            Answer5: answer5,
                            Answer6: '',
                            Answer7: '',
                            Answer8: '',
                            secondsToAnswer: questionTime,
                            correctAnswer: correctAnswer,
                            userTarget: userTarget,
                            coinsToEarn: coinsToEarn
                        }
                    }
                    else if (answer7 === undefined && answer8 === undefined) {
                        submitData = {
                            challengeType: challengeType,
                            challengeTitle: challengeTitle,
                            titleOrder: titleOrder,
                            Level: userLevel,
                            challengeOrder: challengeOrder,
                            questionType: questionType,
                            questionTitle: imageQuestionContent,
                            answersType: answerType,
                            challengeImage: await AnswerFileUpload(challengeImage),
                            answersCount: answerCount,
                            Answer1: answer1,
                            Answer2: answer2,
                            Answer3: answer3,
                            Answer4: answer4,
                            Answer5: answer5,
                            Answer6: answer6,
                            Answer7: '',
                            Answer8: '',
                            secondsToAnswer: questionTime,
                            correctAnswer: correctAnswer,
                            userTarget: userTarget,
                            coinsToEarn: coinsToEarn
                        }
                        // console.log(submitData)
                    }
                    else if (answer8 === undefined) {
                        submitData = {
                            challengeType: challengeType,
                            challengeTitle: challengeTitle,
                            titleOrder: titleOrder,
                            Level: userLevel,
                            challengeOrder: challengeOrder,
                            questionType: questionType,
                            questionTitle: imageQuestionContent,
                            answersType: answerType,
                            challengeImage: await AnswerFileUpload(challengeImage),
                            answersCount: answerCount,
                            Answer1: answer1,
                            Answer2: answer2,
                            Answer3: answer3,
                            Answer4: answer4,
                            Answer5: answer5,
                            Answer6: answer6,
                            Answer7: answer7,
                            Answer8: '',
                            secondsToAnswer: questionTime,
                            correctAnswer: correctAnswer,
                            userTarget: userTarget,
                            coinsToEarn: coinsToEarn
                        }
                        // console.log(submitData)
                    }
                    else {
                        submitData = {
                            challengeType: challengeType,
                            challengeTitle: challengeTitle,
                            titleOrder: titleOrder,
                            Level: userLevel,
                            challengeOrder: challengeOrder,
                            questionType: questionType,
                            questionTitle: imageQuestionContent,
                            answersType: answerType,
                            challengeImage: await AnswerFileUpload(challengeImage),
                            answersCount: answerCount,
                            Answer1: answer1,
                            Answer2: answer2,
                            Answer3: answer3,
                            Answer4: answer4,
                            Answer5: answer5,
                            Answer6: answer6,
                            Answer7: answer7,
                            Answer8: answer8,
                            secondsToAnswer: questionTime,
                            correctAnswer: correctAnswer,
                            userTarget: userTarget,
                            coinsToEarn: coinsToEarn
                        }
                    }
                }

                if (answerType != 'Text') {
                    submitData = {
                        challengeType: challengeType,
                        challengeTitle: challengeTitle,
                        titleOrder: titleOrder,
                        Level: userLevel,
                        challengeOrder: challengeOrder,
                        questionType: questionType,
                        questionTitle: imageQuestionContent,
                        answersType: answerType,
                        challengeImage: await AnswerFileUpload(challengeImage),
                        answersCount: answerCount,
                        secondsToAnswer: questionTime,
                        correctAnswer: correctAnswer,
                        userTarget: userTarget,
                        coinsToEarn: coinsToEarn
                    }
                    for (let key in values) {
                        for (let i = 1; i <= answerCount; i++) {
                            if ((key === `answer${i}Image` || key === 'challengeImage') && values[key]) {
                                if (key === `answer${i}Image`) {
                                    submitData['Answer' + i] = await AnswerFileUpload(values[key])
                                } else {
                                    submitData[key] = await AnswerFileUpload(values[key])
                                }
                            }
                        }
                    }

                }
                const {
                    data: {
                        data,
                        message
                    }
                } = await createChallenges(submitData);
                hideLoader();
                toast.success(message ? message : "Success");
                setTimeout(() => {
                    window.location.href = '/challengescores'

                }, 2000);
            }

        }
        catch (error) {
            const {
                data: {
                    message
                }
            } = error;
            hideLoader();
            toast.error(message ? message : "Failed");
        }
    }
    return (
        <section className={styles.ChallengeScores}>
            <Container fluid className={styles.bghead}>
                <Row>
                    <Col md={12}>
                        <div className={styles.headingSec}>
                            <h3>Challenges & Scores</h3>
                            <p>Please fill all the information for the question</p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid className={styles.bgcon}>
                <Row>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>Challenge Type<span>*</span></label>
                                <Controller
                                    name="challengeType"
                                    control={control}
                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                        <Dropdown
                                            isSearchable={true}
                                            options={challengeTypeOption}
                                            placeholder="Enter challenge type"
                                            className={styles.formControl}
                                            onChange={(e) => onChange(e.value)}
                                            error={errors?.challengeType}
                                            inputRef={ref}
                                        />
                                    )}
                                    rules={{ required: 'Required' }}
                                    onFocus={errors.challengeType?.ref}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>Challenge Title<span>*</span></label>
                                <InputField
                                    // className={styles.formControl}
                                    className={styles.newFormcontrol}
                                    name="challengeTitle"
                                    type="text"
                                    //value={challengeTitle}
                                    register={register}
                                    placeholder="Enter Challenge Title"
                                    onChange={(e) => setChallengeTitle(e.target.value)}
                                    validation={{
                                        required: 'Required'
                                    }}
                                    error={errors.challengeTitle}
                                />

                            </div>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>User Target<span>*</span></label>
                                <Controller
                                    name="userTarget"
                                    control={control}
                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                        <Dropdown
                                            isSearchable={true}
                                            options={userTargetOption}
                                            defaultValue={userTargetOption[1]}
                                            placeholder="Enter user target"
                                            className={styles.formControl}
                                            onChange={(e) => setUserTarget(e.value)}
                                            error={errors?.userTarget}
                                            inputRef={ref}
                                            rules={{ required: 'Required' }}
                                        />
                                    )}

                                    onFocus={errors.userTarget?.ref}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className={styles.newinput}>
                            <div className={styles.FormGroup}>
                                <label>Question Type<span>*</span></label>
                                <Controller
                                    name="questionType"
                                    control={control}
                                    render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                        <Dropdown
                                            options={questionTypeOption}
                                            placeholder="Choose question type"
                                            defaultValue={questionTypeOption[1]}
                                            className={styles.formControl}
                                            onChange={(e) => setQuestionType(e.value)}
                                            error={errors?.questionType}
                                            inputRef={ref}
                                            rules={{ required: 'Required' }}
                                        />
                                    )}

                                    onFocus={errors.questionType?.ref}
                                />
                            </div>
                        </div>
                    </Col>
                    {questionType != 'Text' &&
                        <Col md={4}>
                            <div className={styles.newinput}>
                                <div className={styles.FormGroup}>
                                    <label>Text for the Image</label>
                                    <InputField
                                        className={styles.newFormcontrol}
                                        name="imageQuestionContent"
                                        type="text"
                                        placeholder="Place your question content here"
                                        register={register}
                                        validation={{
                                            required: 'Required'
                                        }}
                                        error={errors?.imageQuestionContent}
                                    />
                                </div>
                            </div>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col md={7}>
                        <Row>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Level<span>*</span></label>
                                        <Controller
                                            name="userLevel"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={userLevelOption}
                                                    placeholder="Enter user level"
                                                    defaultValue={userLevelOption[0]}
                                                    className={styles.formControl}
                                                    onChange={(e) => setUserLevel(e.value)}
                                                    error={errors?.userLevel}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                />
                                            )}

                                            onFocus={errors.userLevel?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Question Time<span>*</span></label>
                                        <Controller
                                            name="questionTime"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={questionTimeOption}
                                                    placeholder="Enter question time"
                                                    defaultValue={questionTimeOption[0]}
                                                    className={styles.formControl}
                                                    onChange={(e) => setQuestionTime(e.value)}
                                                    error={errors?.questionTime}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                />
                                            )}

                                            onFocus={errors.questionTime?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Coins To Earn<span>*</span></label>
                                        <InputField
                                            className={styles.newFormcontrol}
                                            name="coinsToEarn"
                                            type="text"
                                            placeholder="Score for this question"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^(?!0+$)[0-9]{1,10}$/,
                                                    message: 'Score can not be 0 and aplhabets'
                                                }
                                            }}
                                            error={errors?.coinsToEarn}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <AllTitleOrder
                            isOpen={isTitleOrder}
                            //  data={jobsData}
                            handleClose={() => setisTitleOrder(false)}

                        />
                        <Row>
                            <Col md={6}>
                                <div className={styles.newButton} >
                                    <div className={styles.FormGroup}>
                                        <label>Title Order<span>*</span></label>
                                        <InputField
                                            className={styles.formControl}
                                            name="titleOrder"
                                            type="text"
                                            placeholder="Enter title order"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^(?!0+$)[0-9]{1,10}$/,
                                                    message: 'Title order can not be 0 and aplhabets'
                                                }
                                            }}
                                            error={errors?.titleOrder}
                                        />
                                    </div>
                                    <button className={styles.chlButton} onClick={() => setisTitleOrder(true)}>Check order</button>
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className={styles.newButton} >
                                    <div className={styles.FormGroup}>
                                        <label>Challenge Order<span>*</span></label>
                                        <InputField
                                            className={styles.formControl}
                                            name="challengeOrder"
                                            type="text"
                                            placeholder="Enter challenge order"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                                pattern: {
                                                    value: /^(?!0+$)[0-9]{1,10}$/,
                                                    message: 'Challenge order can not be 0 and aplhabets'
                                                }
                                            }}
                                            error={errors?.challengeOrder}
                                        />
                                    </div>
                                    <button className={styles.chlButton} onClick={() => setisCheckOrder(true)}>Check challenge order</button>
                                </div>
                            </Col>
                        </Row>
                        <AllChallangeOrder
                            isOpen={isCheckOrder}
                            data={challengeTitle}
                            handleClose={() => setisCheckOrder(false)}

                        />
                        <Row>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer Type<span>*</span></label>
                                        <Controller
                                            name="answerType"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={answerTypeOption}
                                                    placeholder="Choose answer type"
                                                    defaultValue={answerTypeOption[1]}
                                                    className={styles.formControl}
                                                    onChange={(e) => setAnswerType(e.value)}
                                                    error={errors?.answerType}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                />
                                            )}

                                            onFocus={errors.answerType?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Answers Count<span>*</span></label>
                                        <Controller
                                            name="answerCount"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={answerCountOption}
                                                    placeholder="Choose answer count"
                                                    defaultValue={answerCountOption[2]}
                                                    className={styles.formControl}
                                                    onChange={(e) => setAnswerCount(e.value)}
                                                    error={errors?.answerCount}
                                                    inputRef={ref}
                                                    rules={{ required: 'Required' }}
                                                />
                                            )}

                                            onFocus={errors.answerCount?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={styles.newinput}>
                                    <div className={styles.FormGroup}>
                                        <label>Correct Answer<span>*</span></label>
                                        <Controller
                                            name="correctAnswer"
                                            control={control}
                                            render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                <Dropdown
                                                    options={correctAnswerOption}
                                                    placeholder="Choose correct answer"
                                                    className={styles.formControl}
                                                    onChange={(e) => onChange(e.value)}
                                                    error={errors?.correctAnswer}
                                                    inputRef={ref}
                                                />
                                            )}
                                            rules={{ required: 'Required' }}
                                            onFocus={errors.correctAnswer?.ref}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={5}>
                        {questionType == 'Text' &&
                            <div className={styles.contIn}>
                                <div className={styles.FormGroup}>
                                    <label>Challenge question</label>
                                    <InputField
                                        className={styles.newFormcontrol}
                                        name="imageQuestionContent"
                                        type="text"
                                        placeholder="Place your question content here"
                                        register={register}
                                        validation={{
                                            required: 'Required'
                                        }}
                                        error={errors?.imageQuestionContent}
                                    />
                                </div>
                            </div>
                        }
                        {questionType != 'Text' &&
                            <div className={styles.uplodImage}>
                                <div className={styles.bgupload}>
                                    <div className={styles.imgUploaded}>
                                        <img src={challengeImageUrl} alt="" id="representativeImage" />
                                        {/* <img src={uplod}/> */}

                                    </div>
                                    <div className={styles.uploadnew}>
                                        <img src={uplod} />
                                        <h3>Upload your image</h3>
                                        <div className={styles.upLoadSec}>
                                            <p className={styles.broButton}>Browse file</p>
                                            <Controller
                                                name="challengeImage"
                                                control={control}

                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.inputImage}
                                                        type="file"
                                                        accept="image/*, video/*"
                                                        id="challengeImage"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.challengeImage?.ref}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {errors && errors.challengeImage && <span className="errorMessage">Required</span>}
                            </div>
                        }
                    </Col>
                </Row>
                {answerCount == 2 && answerType == "Text" &&
                    <Row className={styles.twoText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={6} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 1</label>
                                        {/* <input  placeholder="Your first answer here"/> */}
                                        <InputField
                                            className={styles.formControl}
                                            name="answer1"
                                            type="text"
                                            placeholder="Your first answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required',
                                            }}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 2</label>
                                        {/* <input className={styles.formControl} placeholder="Your second answer here"/> */}
                                        <InputField
                                            className={styles.formControl}
                                            name="answer2"
                                            type="text"
                                            placeholder="Your second answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer2}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerCount == 3 && answerType == "Text" &&
                    <Row className={styles.threeText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 1</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer1"
                                            type="text"
                                            placeholder="Your first answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 2</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer2"
                                            type="text"
                                            placeholder="Your second answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer2}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 3</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer3"
                                            type="text"
                                            placeholder="Your third answer here"
                                            register={register}
                                            error={errors?.answer3}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerCount == 4 && answerType == "Text" &&
                    <Row className={styles.fourText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={3} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 1</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer1"
                                            type="text"
                                            placeholder="Your first answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                                <Col md={3} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 2</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer2"
                                            type="text"
                                            placeholder="Your second answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer2}
                                        />
                                    </div>
                                </Col>
                                <Col md={3} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 3</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer3"
                                            type="text"
                                            placeholder="Your third answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer3}
                                        />
                                    </div>
                                </Col>
                                <Col md={3} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 4</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer4"
                                            type="text"
                                            placeholder="Your fourth answer here"
                                            register={register}
                                            error={errors?.answer4}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerCount == 5 && answerType == "Text" &&
                    <Row className={styles.fiveText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 1</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer1"
                                            type="text"
                                            placeholder="Your first answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 2</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer2"
                                            type="text"
                                            placeholder="Your second answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer2}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 3</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer3"
                                            type="text"
                                            placeholder="Your third answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer3}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 4</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer4"
                                            type="text"
                                            placeholder="Your fourth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer4}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 5</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer5"
                                            type="text"
                                            placeholder="Your fifth answer here"
                                            register={register}
                                            error={errors?.answer5}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerCount == 6 && answerType == "Text" &&
                    <Row className={styles.sixText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 1</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer1"
                                            type="text"
                                            placeholder="Your first answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 2</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer2"
                                            type="text"
                                            placeholder="Your second answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer2}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 3</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer3"
                                            type="text"
                                            placeholder="Your third answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer3}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 4</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer4"
                                            type="text"
                                            placeholder="Your fourth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer4}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 5</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer5"
                                            type="text"
                                            placeholder="Your fifth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer5}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 6</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer6"
                                            type="text"
                                            placeholder="Your sixth answer here"
                                            register={register}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerCount == 7 && answerType == "Text" &&
                    <Row className={styles.sevenText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 1</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer1"
                                            type="text"
                                            placeholder="Your first answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 2</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer2"
                                            type="text"
                                            placeholder="Your second answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer2}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 3</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer3"
                                            type="text"
                                            placeholder="Your third answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer3}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 4</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer4"
                                            type="text"
                                            placeholder="Your fourth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer4}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 5</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer5"
                                            type="text"
                                            placeholder="Your fifth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer5}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 6</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer6"
                                            type="text"
                                            placeholder="Your sixth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer6}
                                        />
                                    </div>
                                </Col>
                                <Col md={12} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 7</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer7"
                                            type="text"
                                            placeholder="Your seventh answer here"
                                            register={register}
                                            error={errors?.answer7}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerCount == 8 && answerType == "Text" &&
                    <Row className={styles.eightText}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 1</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer1"
                                            type="text"
                                            placeholder="Your first answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer1}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 2</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer2"
                                            type="text"
                                            placeholder="Your second answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer2}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 3</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer3"
                                            type="text"
                                            placeholder="Your third answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer3}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 4</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer4"
                                            type="text"
                                            placeholder="Your fourth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer4}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 5</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer5"
                                            type="text"
                                            placeholder="Your fifth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer5}
                                        />
                                    </div>
                                </Col>
                                <Col md={4} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 6</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer6"
                                            type="text"
                                            placeholder="Your sixth answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer6}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 7</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer7"
                                            type="text"
                                            placeholder="Your seventh answer here"
                                            register={register}
                                            validation={{
                                                required: 'Required'
                                            }}
                                            error={errors?.answer7}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className={styles.inNew}>
                                    <div className={styles.FormGroup}>
                                        <label>Answer 8</label>
                                        <InputField
                                            className={styles.formControl}
                                            name="answer8"
                                            type="text"
                                            placeholder="Your eighth answer here"
                                            register={register}
                                            error={errors?.answer8}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }

                {answerType == 'Image' && answerCount == 2 &&
                    <Row className={styles.twoCount}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Answer 1</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer1ImageUrl} />

                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer1Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer1Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer1Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                    </div>
                                    {errors && errors.answer1Image && <span className="errorMessage" >Required</span>}
                                </Col>
                                <Col md={6} className={styles.FormGroup}>
                                    <label>Answer 2</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer2ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer2Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer2Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer2Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                    </div>
                                    {errors && errors.answer2Image && <span className="errorMessage">Required</span>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerType == 'Image' && answerCount == 3 &&
                    <Row className={styles.threeCount}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={4} className={styles.FormGroup}>
                                    <label>Answer 1</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer1ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer1Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        type="file"
                                                        className={styles.fileUploadNew}
                                                        accept="image/*"
                                                        id="answer1Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer1Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                        {errors && errors.answer1Image && <span className="errorMessage">Required</span>}
                                    </div>
                                </Col>
                                <Col md={4} className={styles.FormGroup}>
                                    <label>Answer 2</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer2ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer2Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer2Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer2Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                        {errors && errors.answer2Image && <span className="errorMessage">Required</span>}
                                    </div>
                                </Col>
                                <Col md={4} className={styles.FormGroup}>
                                    <label>Answer 3</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer3ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer3Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer3Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer3Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                        {errors && errors.answer3Image && <span className="errorMessage">Required</span>}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerType == 'Image' && answerCount == 4 &&
                    <Row className={styles.fourCount}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <Col md={3} className={styles.FormGroup}>
                                    <label>Answer 1</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer1ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer1Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer1Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer1Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                        {errors && errors.answer1Image && <span className="errorMessage">Required</span>}
                                    </div>
                                </Col>
                                <Col md={3} className={styles.FormGroup}>
                                    <label>Answer 2</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer2ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer2Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer2Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer2Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                        {errors && errors.answer2Image && <span className="errorMessage">Required</span>}
                                    </div>
                                </Col>
                                <Col md={3} className={styles.FormGroup}>
                                    <label>Answer 3</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer3ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer3Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer3Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer3Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                        {errors && errors.answer3Image && <span className="errorMessage">Required</span>}
                                    </div>
                                </Col>
                                <Col md={3} className={styles.FormGroup}>
                                    <label>Answer 4</label>
                                    <div className={styles.optionImage}>
                                        <img src={answer4ImageUrl} />
                                    </div>
                                    <div className={styles.newUplod}>
                                        <img src={fileUploads} />
                                        <div className={styles.fileUp}>
                                            <Controller
                                                name="answer4Image"
                                                control={control}
                                                render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                    <input
                                                        className={styles.fileUploadNew}
                                                        type="file"
                                                        accept="image/*"
                                                        id="answer4Image"
                                                        onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                    />
                                                )}
                                                rules={{ required: true }}
                                                onFocus={errors.answer4Image?.ref}
                                            />
                                            <h4>Upload your files</h4>
                                        </div>
                                        {errors && errors.answer4Image && <span className="errorMessage">Required</span>}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerType == 'Image' && answerCount == 5 &&
                    <Row className={styles.fiveCount}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <div className={styles.textfive}>
                                    <ul>
                                        <li>
                                            <label>Answer 1</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer1ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUpload} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer1Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer1Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer1Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer1Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 2</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer2ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer2Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer2Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer2Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer1Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 3</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer3ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer3Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer3Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer3Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer3Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 4</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer4ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer4Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer4Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer4Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer4Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 5</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer5ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer5Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer5Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer5Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer5Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerType == 'Image' && answerCount == 6 &&
                    <Row className={styles.sixCount}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <div className={styles.textfive}>
                                    <ul>
                                        <li>
                                            <label>Answer 1</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer1ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer1Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer1Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer1Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer1Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 2</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer2ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer2Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer2Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer2Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer2Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 3</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer3ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer3Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer3Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer3Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer3Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 4</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer4ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer4Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer4Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer4Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer4Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 5</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer5ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer5Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer5Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer5Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer5Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 6</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer6ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer6Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer6Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer6Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer6Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerType == 'Image' && answerCount == 7 &&
                    <Row className={styles.sevenCount}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <div className={styles.textfive}>
                                    <ul>
                                        <li>
                                            <label>Answer 1</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer1ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUpload} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer1Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer1Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer1Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer1Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 2</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer2ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer2Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer2Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer2Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer2Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 3</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer3ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer3Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer3Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer3Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer3Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 4</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer4ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer4Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer4Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer4Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer4Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 5</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer5ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer5Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer5Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer5Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer5Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 6</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer6ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer6Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer6Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer6Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer6Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 7</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer7ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer7Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer7Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer7Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer6Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                }
                {answerType == 'Image' && answerCount == 8 &&
                    <Row className={styles.eightCount}>
                        <Col md={12}>
                            <Row className={styles.boxBor}>
                                <div className={styles.neweight}>
                                    <ul>
                                        <li>
                                            <label>Answer 1</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer1ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer1Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer1Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer1Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer1Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 2</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer2ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer2Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer2Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer2Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer2Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 3</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer3ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer3Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer3Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer3Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer3Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 4</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer4ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer4Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer4Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer4Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer4Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 5</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer5ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer5Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer5Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer5Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer5Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 6</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer6ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer6Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer6Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer6Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer6Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 7</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer7ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer7Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer7Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer7Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer7Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                        <li>
                                            <label>Answer 8</label>
                                            <div className={styles.optionImage}>
                                                <img src={answer8ImageUrl} />
                                            </div>
                                            <div className={styles.imgUp}>
                                                <img src={fileUploads} />
                                                <div className={styles.fileUp}>
                                                    <Controller
                                                        name="answer8Image"
                                                        control={control}
                                                        render={({ field: { onChange, ref }, fieldState: { error } }) => (
                                                            <input
                                                                className={styles.fileUploadNew}
                                                                type="file"
                                                                accept="image/*"
                                                                id="answer8Image"
                                                                onChange={(e) => onChange(e.target.files[0], handleFileUpload(e.target.files[0], e.target.id))}
                                                            />
                                                        )}
                                                        rules={{ required: true }}
                                                        onFocus={errors.answer8Image?.ref}
                                                    />
                                                    <h4>Upload your files</h4>
                                                </div>
                                                {errors && errors.answer8Image && <span className="errorMessage">Required</span>}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                }


                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={12}>
                                <div className={styles.btnstyle}>
                                    <button className={styles.resetCha}>Reset Challenges</button>
                                    <button className={styles.postchl} onClick={handleSubmit(submit)}>Post Challenges</button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </Container>
        </section>

    )
};
export default ChallengeScores;