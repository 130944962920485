import React, { Component } from "react";
import "../src/component.scss";

export default class HoverComponent extends Component {
  render() {
    return (
      <div className={"hover"}>
        <img className={"thumbnail"} alt="Albert Einstein" src="https://images.gr-assets.com/authors/1429114964p2/9810.jpg" />
      </div>
    );
  }
}
